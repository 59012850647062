<script setup lang="ts"></script>

<template>
  <a
    target="_blank"
    href="https://api.whatsapp.com/send/?phone=5586994343540&text=Gostaria+de+conversar+sobre+constru%C3%A7%C3%A3o+e+acompanhamento+de+obra&type=phone_number&app_absent=0"
    class="bg-green-400 py-4 px-6 rounded-xl border-white border-2 cursor-pointer"
  >
    Converse pelo WhatsApp
  </a>
</template>
